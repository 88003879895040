export const sessions = {
    flash_register_success: "You have successfully registered to the Free plan.",
    my_account_link: "/my-account",
    payment_link: "/payment/",
    prices_link: "/prices",
}

export const useInitUser = {
    errorMessage: {
        userToken: "Sorry, we are unable to retrieve your information. Our technical team has been informed. Please refresh the page or try again later.",
        apiKey: "We are unable to retrieve your API key. Please validate the captcha on the My Account page to activate your API key.",
        billingInfo: "Sorry, we are unable to retrieve your billing informations. Our technical team has been informed. Please refresh the page or try again later.",
        billingHistory: "Sorry, we are unable to retrieve your billing history. Our technical team has been informed. Please refresh the page or try again later.",
        userInfo: "Sorry, we are unable to retrieve your user informations. Our technical team has been informed. Please refresh the page or try again later.",
        paymentInfo: "Sorry, we are unable to retrieve your payment informations. Our technical team has been informed. Please refresh the page or try again later.",
        apiUsage: "Sorry, we are unable to retrieve your API informations. Our technical team has been informed. Please refresh the page or try again later.",
        apiUsageHistory: "Sorry, we are unable to retrieve your API history. Our technical team has been informed. Please refresh the page or try again later.",
        learnable: "We could not set you API key. Please try again later",
        anonymize: "We could not set you API key. Please try again later",
        removeUserAccount: "We could not delete yout account. Please try again later",
        availablePlans: "We were unable to get any plans available. Please refresh the page if you have a problem."
    },
    updatedInformation: {
        updateBillingInfo: "invoice information",
    },
    return_flash_updated_success: updatedInformation => `Your ${updatedInformation} has been successfully updated.`,
    return_flash_updated_error: updatedInformation => `Your ${updatedInformation} could not be updated. Please retry later.`,
}


export const useUpdateUserData = {
    flash_update_empty: "There was no information to update.",
    flash_update_name_success: "Your name has been successfully updated.",
    flash_update_name_error: "Your name could not be updated. Please retry later.",
    flash_connexion_old: "Your connection is too old to change your email address. Please log out and log back in to change your email address.",
    flash_update_email_success: "Your email has been updated successfully.",
    flash_update_email_error: "Your email could not be updated. Please retry later.",
    flash_vat_unvalid: "Your VAT ID is not valid.",
    flash_limit_payment: "You cannot modify these limits without registered payment cards.",
    flash_no_value_limit: "There are no new values ​​to update for the hard limit and the soft limit.",
    return_flash_soft_limit: quota => `The minimum soft-limit value accepted is ${quota}.`,
    return_flash_hard_limit: quota => `The minimum hard-limit value accepted is ${quota}.`,
    flash_higher_limit: "Your soft limit cannot be higher than your hard limit.",
    flash_update_soft_limit_error: "We could not update your soft limit. Please try again later.",
    flash_update_hard_limit_error: "We could not update your hard limit. Please try again later.",
    flash_update_soft_limit_success: "Your soft limit has been updated successfully.",
    flash_update_hard_limit_success: "Your hard limit has been updated successfully.",
    flash_update_default_card_error: "Sorry, we could not update your default card. Please try again later.",
    flash_update_default_card_success: "Your default card has been updated successfully.",
    flash_delete_account: "An email has been sent to your address. Click on the link to complete your account deletion.",
    return_flash_delete_card_subscription_error: plan => `You cannot delete all your cards since you're subscribed to a ${plan} plan. Please add another card before deleting this one or subscribe to a BASIC (free) plan first.`,
    return_flash_delete_card_error: last4 => `We could not delete your card n° **** **** **** ${last4}. Please try again later.`,
    return_flash_delete_card_success: last4 => `Your card **** **** **** ${last4} has been deleted successfully.`,
    return_flash_register_plan_success: plan => `You have successfully registered to the ${plan} plan.`,
    return_flash_register_plan_error: plan => `Your subscription to the ${plan} plan didn't work. Please try again later.`,
    my_account_link: "/my-account",
}
