exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-api-documentation-js": () => import("./../../../src/pages/api-documentation.js" /* webpackChunkName: "component---src-pages-api-documentation-js" */),
  "component---src-pages-api-enumerators-js": () => import("./../../../src/pages/api-enumerators.js" /* webpackChunkName: "component---src-pages-api-enumerators-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-csv-excel-tool-js": () => import("./../../../src/pages/csv-excel-tool.js" /* webpackChunkName: "component---src-pages-csv-excel-tool-js" */),
  "component---src-pages-developer-tools-js": () => import("./../../../src/pages/developer-tools.js" /* webpackChunkName: "component---src-pages-developer-tools-js" */),
  "component---src-pages-features-features-json-page-js": () => import("./../../../src/pages/features/{featuresJson.page}.js" /* webpackChunkName: "component---src-pages-features-features-json-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-password-forgotten-js": () => import("./../../../src/pages/password-forgotten.js" /* webpackChunkName: "component---src-pages-password-forgotten-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

